import { useState } from "react";
import "../styles/Signup.css";
import logo from "../assets/Aiq_Logo.svg";
import bro from "../assets/images/signup.svg";
import axios from "axios";

export default function Signup() {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState({});
	const [success,setSuccess]=useState(false)
	const [disable,setDisable]=useState(false)

	async function attemptSignup(event) {
		event.preventDefault();
        let error = Validate(username,email,password)
        setError(error) 
		console.log(error)

        // // // console.log(Validate(userName,email,password.password,mobileNumber,age))
        if(Object.values(error).length===0){
            signup()
        }
	}

	function handleNewUsername(event) {
		setUsername(event.target.value);
	}

	function handleNewEmail(event) {
		setEmail(event.target.value);
	}

	function handleNewPassword(event) {
		setPassword(event.target.value);
	}
	const signup=async()=>{
		setDisable(true)
		const {data}= await axios.post("https://demobe.inofii.com/copilot/register/",{
			username: username,
			email: email,
			// age: age,
			// phone_number: mobileNumber,
			// role: "user",
			password:password
		})
		setDisable(false)
		setSuccess(true)
		setEmail("")
		setUsername("")
		setPassword("")



	}
	const Validate=(userName,email,password)=>{
        let error={};

        const regex=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const userName_regex = /\s/g
        if(!userName)
        {
            error.name="*Username is required"
        }
        else if (userName_regex.test(userName))
        {
            // // // console.log(userName_regex.test(userName))
            error.name = "User name may contain only numbers, letters, and special characters."
        }
        if(!email)
        {
            error.email="*Email is required"
        }
        else if(!regex.test(email)){
            error.email="This is not a valid email"
        }
        if(!password)
        {
            error.password="*Password is required"
        }
       
      
        return error


    }

	return (
		<div className="signupContainer">
			<div id="background"></div>
			<div id="signup-container">
				<div id="signup">
					<img src={logo} style={{width:167}} alt="Wolverine" />
					<div className="txt">
						<h1>Signup</h1>
						{success  ? (
							<p className="success">we sent the verification email to registered email! </p>
						) : (
							<p>Create an account to get started!</p>
						)}
					</div>
					<form>
						<input
							type="username"
							placeholder="Name"
							value={username}
							onChange={handleNewUsername}
						/>

						{error.name !== "" ? (
							<p className="error">{error.name}</p>
						):null}
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={handleNewEmail}
						/>
						{error.email !== "" ? (
							<p className="error">{error.email}</p>
						):null}
						<input
							type="password"
							placeholder="Password"
							value={password}
							onChange={handleNewPassword}
						/>
						{error.password !== "" ? (
							<p className="error">{error.password}</p>
						):null}
						<input
						disabled={disable}
							type="submit"
							value="Sign up"
							onClick={attemptSignup}
						/>
					</form>
					<p>
						By clicking "Sign up", you agree to the{" "}
						<a href="/terms">Terms of Use</a> and{" "}
						<a href="/privacy">Privacy Policy</a>
					</p>
					<p>
						Already have an account? <a href="/login">Login</a>
					</p>
				</div>
				<div id="privacy">
					<img src={bro} alt="" />
					<p>
						Your data is safe with us, we prioritize your privacy
						and security.
					</p>
				</div>
			</div>
		</div>
	);
}
