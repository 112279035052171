import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Login from "./pages/Login";
import Signup from "./pages/Signup";

import IstoryBoard from "./pages/istoryBoard";
import DataAnalysis from "./pages/DNA";

import { Box, CircularProgress } from "@mui/material";
import useFetch from "./components/useFetch";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
	const { loading, error, websiteContent } = useFetch(`https://cms.istorylive.com/api/nlu-websites/1/?populate=*`);
  
	if (loading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				<CircularProgress />
			</Box>
		);
	}
	if (error) return <p> Error :( </p>;
	return (
		<>
			<div>
				<Routes>
					<Route exact path="/" element={<Index content={websiteContent} />}></Route>
					<Route exact path="/login" element={<Login />}></Route>
					<Route exact path="/signup" element={<Signup />}></Route>
					{/* <Route exact path="/signup" element={<Signup />}></Route> */}
					<Route exact path="/assitive_ai" element={<IstoryBoard/>}></Route>
					<Route exact path="/csv_qa" element={<DataAnalysis/>}></Route>
					<Route exact path="/terms" element={<Terms content={websiteContent}/>}></Route>
					<Route exact path="/privacy" element={<Privacy content={websiteContent}/>}></Route>
				</Routes>
			</div>
		</>
	);
}

export default App;
