import "../styles/DNA.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Box, Button,TextareaAutosize, TextField, Checkbox, Divider, Typography, Modal, CircularProgress, Tooltip ,Grid, Card, CardContent, Stack, Menu, MenuItem, MenuList, ListItem, ListItemIcon, ListItemText, CardMedia,List,ListItemButton,Radio,InputBase } from "@mui/material"

import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';

import { VictoryBar, VictoryPie } from "victory";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import jwtDecode from "jwt-decode";

const ChatMessage = React.memo(({index, message }) => {
	const response = message.response;
	let data;
	const colors = [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			  ]
	if(message.response.viz === "barChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "doughnutChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors: colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "pieChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors  :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "lineChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors: colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "polarareaChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else{
		data = {
			// labels: ['My First Dataset', 'My second Dataset', 'My third Dataset', 'My Four Dataset', 'My five Dataset'],
			labels: message.response.labels,
			datasets: [{
			//   label: message.labels,
			data: message.response.dataValues,
			//   data: [59, 81, 56, 55, 40],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			  ],
			  borderColor: [
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
			  ],
			  borderWidth: 1
			}]
		  };
	}

	// console.log("================================", datasets2)
	  const chartOptions = {
		scales: {
			x: {
			  ticks: {
				color: 'white', // Change this to the desired color
			  },
			},
			y: {
			  ticks: {
				color: 'white', // Change this to the desired color
			  },
			},
		  },
		onClick: (event, elements) => {
		  if (elements.length > 0) {
			const datasetIndex = elements[0].datasetIndex;
			const dataIndex = elements[0].index;
			const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
			// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
		  }
		},
		responsive: true,
		plugins: {
		  legend: {
			onClick: (e, legendItem, legend) => {
				const chart = legend.chart;
				const datasetIndex = chart.data.labels.indexOf(legendItem.text)
				chart.toggleDataVisibility(datasetIndex)
				chart.update(); 
			  },
			display:true,
			position:'right',
			labels: {		
				generateLabels: (chart) => {
					let visibility = []
					for(let i=0;i<chart.data.labels.length;i++){
						if(chart.getDataVisibility(i)===true){
							visibility.push(false)
						}else{
							visibility.push(true)
						}
					}
				  const originalLabels = chart.data.labels;
				  const originalColors = chart.data.datasets[0].backgroundColor;
				  const labels = [];
		
				  originalLabels.forEach((label, index) => {
					labels.push({
					  text: label,
					  fontColor: 'white',
					  fillStyle: originalColors[index],
					  hidden:visibility[index]
					});
				  });
		
				  return labels;
				},
			  },
		  },
		  title: {
			display: true,
			text: 'Visualization',
			color: 'white',
		  },
		},
	  };
	  const value="section_"+index
	  
	//////////////////////////////////////////////////////////////// REFERENECE FOR STACKED BAR CHARTS //////////////////////////////////////////////////////////////////
	//   const chartData1 = {
	// 	labels: ["India", "China", "Malaysia"],
	// 	datasets: [
	// 	  {
	// 		label: 'Male',
	// 		backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue
	// 		borderColor: 'rgb(54, 162, 235)',
	// 		borderWidth: 1,
	// 		data: [200, 180, 150]
	// 	  },
	// 	  {
	// 		label: 'Female',
	// 		backgroundColor: 'rgba(255, 99, 132, 0.6)', // Pink
	// 		borderColor: 'rgb(255, 99, 132)',
	// 		borderWidth: 1,
	// 		data: [132, 120, 110]
	// 	  }
	// 	]
	//   };
	
	//   const chartOptions1 = {
	// 	scales: {
	// 	  x: { stacked: true },
	// 	  y: { stacked: true }
	// 	}
	//   };

	return (
		<Grid id={value} container p={4}>
		<Grid sx={{borderBottom:"2px solid grey"}} item xs={12}>
				
			<Box>
				<h1 style={{color:"#11dd2a"}}>
				{message.message}
				</h1>
			</Box>
			<Box>
			<h3>
				{message.response.message}
			</h3>
			</Box>
			<Box>
			{message.response.viz === "pieChart" && (
				<>
					<Box className="chart-container" sx={{width:"400px",textAlign:"-webkit-center"}} >
						<Pie data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{/* ////////////REFERENCE FOR STACKED BAR CHARTS//////////// */}
			{/* {message.response.viz === "barChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<Bar data={chartData1} options={chartOptions1} />
					</Box>
				</>
			)} */}
			{message.response.viz === "barChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<Bar data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "doughnutChart" && (
				<>
					<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
						<Doughnut data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "lineChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<Line data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "polarareaChart" && (
				<>
					<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
						<PolarArea data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "radarChart" && (
				<>
					<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
						<Radar data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "scatterChart" && (
				<>
					<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
						<Scatter data={data} options={chartOptions} />
					</Box>
				</>
			)}
			{message.response.viz === "bubbleChart" && (
				<>
					<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
						<Bubble data={data} options={chartOptions} />
					</Box>
				</>
			)}
			</Box>
		</Grid>
		{/* <div
			className={`chat-message ${
				(message.user === "ai" && "chatgpt") ||
				(message.user === "user" && "customer")
			}`}
		>
			<div className="chat-message-center">
				<div>
					<div
						className={`avatar ${
							(message.user === "ai" && "chatgpt") ||
							(message.user === "user" && "customer")
						}`}
					>
						{message.user === "ai" && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								strokeWidth={1.5}
								viewBox="0 0 40 40"
							>
								<path
									fill="currentColor"
									d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
								/>
							</svg>
						)}
					</div>
				</div>
				<div className="message">
					<div>{message.message}</div>
					
					{message.response.viz === "pieChart" && (
						<>
							<Box className="chart-container" sx={{width:"400px",textAlign:"-webkit-center"}} >
								<Pie data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "barChart" && (
						<>
							<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
								<Bar data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "doughnutChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Doughnut data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "lineChart" && (
						<>
							<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
								<Line data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "polarareaChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<PolarArea data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "radarChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Radar data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "scatterChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Scatter data={data} options={chartOptions} />
							</Box>
						</>
					)}
					{message.response.viz === "bubbleChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Bubble data={data} options={chartOptions} />
							</Box>
						</>
					)}
					
				</div>
				<div ref={bottomRef} />
			</div>
		</div> */}
		</Grid>
	);
});

export default function DNA() {
	const API_BASE = "https://demobe.inofii.com/copilot/";
	// const API_BASE = "http://localhost:8000/copilot/";
	const [question, setQuestion] = useState("");
	const { REACT_APP_NLU_IL_URL } = process.env;
	const [chatLog, setChatLog] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [questionView, setQuestionView] = useState(false);
	const [fileType, setFileType] = useState(null);
	const [filename, setfileName] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [history,setHistory]=useState([])
	const messagesEndRef = useRef(null)
	const [api_usage,setApi_usage]=useState(0)
	const [restrictionOpen,setRestrictionOpen] = useState(false)

	const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
	const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
	const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
	const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });

	const modal_style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 370,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		backgroundColor: '#141313'
	};

	useEffect(() => {
        // console.log("inside useEffect")
        const storedToken = localStorage.getItem('token');
        // console.log("coming",jwtDecode(storedToken).user_api_usage.dna)
        if (!storedToken) {
			
			// console.log("inside else")
            // Redirect to login page
            navigate("/login");
        }
		// else{
		// 	setApi_usage(jwtDecode(storedToken).user_api_usage.dna)

		// }
      }, []);

	const navigate = useNavigate();
	const bottomRef = useRef(null);
	const victoryColors = [
		"#d66",
		"#d96",
		"#dd6",
		"#9d6",
		"#6d6",
		"#6d9",
		"#6dd",
		"#69d",
		"#66d",
		"#96d",
		"#d6d",
		"#d69",
	];
	const victoryTheme = {
		bar: {
			style: {
				data: {
					fill: "#88d",
				},
				labels: {
					padding: 3,
					fontSize: 12,
					fill: "white",
				},
			},
		},
		pie: {
			colorScale: victoryColors,
			width: 350,
			height: 380,
			style: {
				labels: {
					padding: 20,
					fill: "white",
				},
			},
		},
	};

	useEffect(() => {
		// 👇️ scroll to bottom every time messages change
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [chatLog]);

	const storedToken = localStorage.getItem('token');
	if (!storedToken) {
		// Redirect to login page
		setTimeout(() => navigate("/login"), 0);
	}


	const handleFileChange = event => {
		setSelectedFile(event.target.files[0]);
		uploadCSV(event.target.files[0]);
	};

	const uploadCSV = async selectedFile => {
		console.log("upload csv file console")
		const formData = new FormData();
		formData.append("file_url", selectedFile);
		const extension = selectedFile.name.split(".").pop();
		const filename = selectedFile.name
		setfileName(filename)
		setFileType(extension);

		const response = await axios.post(
			"https://testil.nlugpt.io/wolverine/uploadcsv/",
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		if (response.status === 201) {
			openFileDialog();
			
			// Handle the extracted data returned from the backend
		} else {
			throw new Error("File upload failed.");
		}
	};

	const loadFile = () => {
		document.getElementById("dna-input").click();
	}


	const openFileDialog = async() => {
		const api_usage_response = await axios.post(API_BASE + 'api_usage/',{"user_id":jwtDecode(storedToken).user_id,"type":"dna"})
		if((api_usage_response.status === 200 && api_usage_response.data.count >= 0 )|| api_usage_response.data.user==7){

			setQuestionView(true);
			setChatLog([]);
			// document.getElementById("dna-input").click();
		}
		else if (api_usage_response.status === 200 && api_usage_response.data.count<0){
			setRestrictionOpen(true)
			setLoading(false);
		}
		else{
			setLoading(false);
		}
	};

	function handleSubmit(event) {
		validateInputAndCallAPI();
		event.preventDefault(); // 👈️ prevent page refresh
	}
	const RestrictionhandleClose = () => setRestrictionOpen(false);

	async function validateInputAndCallAPI() {
		if (question === "") {
			alert("Please provide Question before calling this API");
			return;
		} else {
			let chatLogNew = [...chatLog, { user: "user", message: question }];
			// setChatLog(chatLogNew);
				  setLoading(true);
				  let response = await axios.post(
					  "https://testil.nlugpt.io/wolverine/intelligenceLayer/",
					  {
						  userIntent: question,
						  file_type: fileType,
						  file_name:filename
					  }
				  );
				  setHistory((previousvalue)=>{
					  const value=[...previousvalue,question]
					  return value
				  })
				  setQuestion("");
				  if (response.data) {
					  if (response.data.message) {
						  const data = response.data.message;
						  let newChatLog = [
							  ...chatLog,
							  { user: "user", message: question, response: { user: "ai", message: `${data}` } }
							  
						  ];
						  setChatLog(newChatLog);
					  } else {
						  const graph = response.data.pieChart || response.data.barChart || response.data.doughnutChart || response.data.lineChart
										  || response.data.polarareaChart || response.data.radarChart || response.data.scatterChart || response.data.bubbleChart;
	  
						  const { dataValues1, stacked } = processGraph(graph);
						//   console.log("================================ stacked", stacked);
						//   console.log("================================ datavales", dataValues1);
						  let newChatLog = [
							  ...chatLog,
							  { user: "user", message: question, response:
								  {
									  user: "ai",
									  message: "Here is your visualization:",
									  viz: Object.keys(response.data)[0],
									  data: Object.entries(graph).map(([x, y]) => ({
										  x: `${x} (${y})`,
										  y,
									  })),
									  dataValues1,
									  stacked,
									  dataValues: Object.values(graph),
									  labels: Object.keys(graph)
								  }
							  }
							  
						  ];
						  setChatLog(newChatLog);
					  }
					  setLoading(false);
					  return true;
			}
			
		}
	}

	function processGraph(graph) {
		// console.log("process graph function----------------------------------------------------------------")
		
		let stacked= false;

		const dataValues1 = {
		//   label: [],
		};
	  
		if (typeof Object.values(graph)[0] === 'object') {
		  const subKeys = Object.keys(graph[Object.keys(graph)[0]]);
		  subKeys.forEach(subKey => {
			// dataValues1.label.push(subKey);
			dataValues1[subKey.toLowerCase()] = Object.values(graph).map(item => item[subKey]);
		  });
		  stacked = true
		} else {
			// dataValues1.label = Object.keys(graph);
		  Object.keys(graph).forEach(key => {
			dataValues1[key] = [graph[key]];
		  });
		  stacked = false
		}
		// console.log("data", dataValues1);
		return { dataValues1, stacked };
	  }


useEffect(()=>{

	if(messagesEndRef.current){
		messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
	}
	
},[isLoading])

const scrollQuestion=(index)=>{
	let id="section_"+index
	const targetElement = document.getElementById(id);
	// console.log(targetElement)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }

}
	return (
		<div className="App">
			<Header />
			<aside className="sidemenu">
				<div style={{ display: "none" }}>
					<input
						type="file"
						onChange={handleFileChange}
						accept="text/csv, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						id="dna-input"
					/>
				</div>
				<div className="sidemenu-button" onClick={loadFile}>
					<span>+</span>
					Upload CSV/PDF
				</div>
				{
					questionView &&
					<div>
						<form onSubmit={handleSubmit}>
							<div>
								<input
									id="userInt"
									className="chat-input-textarea"
									rows="3"
									placeholder="Enter your question"
									value={question}
									onChange={e => {
										setQuestion(e.target.value);
									}}
									></input>
							

							</div>
							<div>
							<button className="btn-form-submit" onClick={handleSubmit}>Send</button>
							</div>
						</form>
					</div>
					// </form>
				}
				{
					history.length>0 ? <div className="history">
						<div className="history_heading">
					<p>History</p></div>
					<div className="history-container">
                        {history.map((value,index)=>{
						return (
							<div className="history_topics" onClick={()=>scrollQuestion(index)}><p className="history_text" >
								{value}
							</p>

							</div>
						)
					} 
						
					)}
					</div>
				</div>:null
				}
			</aside>
			<section className="chatbox">
				{questionView ? (
					<div className="chat-log" ref={messagesEndRef}>
						{isLoading &&
							(
							<div
								style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								backgroundColor: '#343541',
								}}
							>
								<div className="loading-container">
								{/* <div className="dot-animation">
									<span></span>
									<span></span>
									<span></span>
								</div> */}
								<CircularProgress />
								</div>
							</div>
							)
						}
						
						{isLoading ? null : chatLog.map((message, index) => (
							<ChatMessage index={index} message={message}/>
						)) }
					</div>
				) : (
					<div className="csv-upload-first">
						<h1>Data Analysis</h1>
						<p>
							Upload a file on the left to ask the AI questions.
						</p>
						<div>
							<div>
								<h2>Current features</h2>
								<p>
									Upload Excel, CSV, or PDF files.<br />
									Ask the AI questions about the data and get answers.<br />
									The AI can visualize data as pie charts or bar charts.
								</p>
							</div>
							<div>
								<h2>Tips & Tricks</h2>
								<p>
									When using Excel or CSV, try to ask for visualizations of<br />
									a whole column of data instead of individual pieces of data<br />
									contained in the column.
								</p>
							</div>
						</div>
					</div>
				)}
			</section>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={restrictionOpen}
				onClose={RestrictionhandleClose}
				closeAfterTransition
			>
				<Box sx={{...modal_style,textAlign:"center"}}>
					<Typography sx={{ color: "white", mb:2 }} variant='h5'>
					Out of demo credits? Contact us for more.
					</Typography>
					<Button variant='contained' sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }}>

					<Box component="a" href="https://arivu-iq.com/#ourContact" target="_blank" sx={{textDecoration:"none"}} >Contact US</Box>
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
