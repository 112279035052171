import asistive from "../../assets/icons/design-ideas_svgrepo.com.svg"
import visual from "../../assets/icons/dimensional-view_svgrepo.com.svg"
import health from "../../assets/icons/health-care-love_svgrepo.com.svg"

export default function Product({alt, title, description }) {
	let link
	let soon
	let act_color
	let img
	if(title=="Creative Experience"){
		link="/assitive_ai"
		act_color = "#E74E6E"
		img = asistive

	}
	else if(title=="Visualization"){
		link="/csv_qa"
		act_color = "#FD8819"
		img = visual
	}
	else if(title=="Health Care"){
		link="#"
		act_color = "#E74E6E"
		soon=true
		img = health
		
	}
	
	return<a href={link} style={{textDecoration:"none",cursor:soon ? null:"pointer"}}><div style={{ width: 320, minHeight: 250, padding: 32, display: "flex", flexDirection: "column", alignItems: "center", gap: 8, boxShadow: "0px 0px 25px rgba(51, 51, 111, 0.08)" }}>
		<div style={{ width: 72, minHeight: 72, display: "flex", justifyContent: "center", alignItems: "center" }}>
			<img src = {img} alt={alt} style={{opacity:soon?0.5:1}}/>
		</div>
		<h3 style={{ fontSize: 28, color: act_color, margin: 0, marginTop: 16,opacity:soon?0.5:1 }}>{title}</h3>
		
		<p style={{ fontSize: 18, color: "var(--alt-color)", margin: 0,opacity:soon?0.5:1 }}>{description}</p>
		{soon? <p style={{ fontSize: 18, color: "var(--alt-color)", margin: 0 }}>Coming Soon . . .</p>:null}
	</div></a> 
}