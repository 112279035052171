import { useState } from "react";
import "../styles/Header.css";
import logo from "../assets/AIQ_Logo_Black.svg"
import aiq_logo from "../assets/Wolverine_AIQ-01.svg"
import Dropdown from "../assets/icons/Dropdown.svg";
import Search from "../assets/icons/Search.svg";
import Hamburger from "../assets/icons/Hamburger-Menu.svg";
import MobileSearch from "../assets/icons/Mobile-Search.svg";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BlueArrowRight from "../assets/icons/Blue-Arrow-Right.svg";
import asistive from "../assets/icons/design-ideas_svgrepo.com.svg"
import health from "../assets/icons/health-care-love_svgrepo.com.svg"
import visual from "../assets/icons/dimensional-view_svgrepo.com.svg"
import { Tooltip } from "@mui/material"; 

export default function Header() {
	let navigate=useNavigate()
	let [mobileOpen, setMobileOpen] = useState(false);
	let [searchOpen, setSearchOpen] = useState(false);
	let [search, setSearch] = useState("");``
	let accesskey=localStorage.getItem('token');
	function toggleMobile() {
		setMobileOpen(() => !mobileOpen);
	}

	function toggleSearch() {
		// Auto-focus search when opening it
		if (!searchOpen) {
			document.querySelector("#search input[type='search']").focus();
		}
		setSearchOpen(() => !searchOpen);
	}

	function onSearchInput(e) {
		setSearch(() => e.target.value);
	}

	function logout(){
		localStorage.removeItem("token")
		navigate("/login")
	}
	return (
		<div id="header-container">
			<div id="header">
				<div style = {{display:"flex", flexDirection:"row"}}>
				{/* <a href="/" id="header-logo">
					<img src={aiq_logo} style={{width:167}} alt="Wolverine, a next-generation AI" />
				</a> */}
                <a href="https://www.arivu-iq.com/" id="header-logo">
					<img src={logo} style={{width:72, marginRight:"100px"}} />
				</a>
				</div>
				<div id="header-sections">
					{accesskey ? (
						<>
							<a href="/">Dashboard</a>
							{/* <a href="#">Documentation</a> */}
						</>
					) : (
						<>
							<a href="/">
								{location.pathname === "/" ? (
									<b>Home</b>
								) : (
									"Home"
								)}
							</a>
							{/* <a href="#">About</a> */}
							{/* <a href="#">
								<div>
									Products <img src={Dropdown} alt="" />
								</div>
							</a> */}
						</>
					)}
					<div>
						<div>
							{["/assitive_ai", "/csv_qa"].includes(
								location.pathname
							) ? (
								<b>Demo</b>
							) : (
								"Demo"
							)}
							<img src={Dropdown} alt="" />
						</div>
						<div className="header-dropdown">
							<a href="/assitive_ai" className="listing" >
								
								<img src={asistive} style={{width:"100px"}}/>
								<div>
									<h4>Creative Experience</h4>
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> */}
								</div>
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> */}
							</a>
							<div  className="listing" style={{opacity:"0.5"}}>
								
								<img src={health} style={{width:"100px"}}/>
								<div>
									<h4>Raptor (Health Care)</h4>
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> */}
								</div>
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> */}
							</div>
							{/* <a href="/csv_qa" className="listing" >
								
								<img src={visual} style={{width:"100px"}}/>
									<div>
									<h4 style={{color: "#FD8819"}}>Visualization</h4>
									{/* <p>
										Yorem ipsum dolor sit amet, consectetur
										adipiscing elit.
									</p> 
								</div>
								
								{/* <p className="learn-more">
									<span>Learn more</span>
									<img src={BlueArrowRight} />
								</p> 
							</a> */}
						</div>
					</div>
					 <a href="https://arivu-iq.com/#ourContact" target="_blank">Contact</a>
				</div>
				<div id="header-buttons">
					{/* <button className="search-button" onClick={toggleSearch}>
						<img src={Search} alt="Search" />
						<input
							id="large-search"
							className={searchOpen ? "search-open" : ""}
							type="search"
							placeholder="Search..."
							value={search}
							onInput={onSearchInput}
							onClick={e => e.stopPropagation()}
						/>
					</button> */}
					{accesskey ? (
						<Tooltip title="Logout">
						<button className="get-logout" onClick={logout}>
							<PowerSettingsNewIcon />
						</button>
						</Tooltip>
					) : (
						<a href="/login">
							<button className="get-started font orange-on-blue">
								Get started
							</button>
						</a>
					)}
				</div>
				<button id="header-mobile-button" onClick={toggleMobile}>
					<img src={Hamburger} alt="open menu" />
				</button>
			</div>
			{mobileOpen && (
				<div id="header-mobile">
					{/* <div id="mobile-search">
						<input
							type="text"
							placeholder="Search"
							value={search}
							onInput={onSearchInput}
						/>
						<button>
							<img src={MobileSearch} alt="Search" />
						</button>
					</div> */}
					<a className="mobile-section" href="/">
						Home
					</a>
					{/* <a className="mobile-section" href="#">
						Products
					</a> */}
					<a className="mobile-section" href="/assitive_ai">
					Creative Experience
					</a>
					{/* <a className="mobile-section" href="/csv_qa">
					Visualization
					</a> */}
					<a className="mobile-section" style={{opacity:"0.5"}}>
					Raptor (Healt Care)
					</a> 
					<a
						className="mobile-section"
						href="https://arivu-iq.com/#ourContact" 
						target="_blank"
						onClick={toggleMobile}
					>
						Contact Us
					</a>
					{accesskey ? (
						<a
							className="mobile-section login-highlight"
							onClick={logout}
						>
							Logout
						</a>
					) : (
						<a
							className="mobile-section login-highlight"
							href="/login"
						>
							<b>Get Started</b>
						</a>
					)}
				</div>
			)}
			<div id="ghost-header"></div>
			{/* <div id="search" className={searchOpen ? "search-open" : ""}>
				<input
					type="search"
					placeholder="Search..."
					value={search}
					onInput={onSearchInput}
				/>
				<button>Search</button>
			</div> */}
		</div>
	);
}
