import { useState, useEffect } from 'react';
import axios from 'axios';
import './istoryBoard.css';
import Header from "../components/Header";
import { Container, Box, Modal,Grid, Fade, Backdrop, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import CustomToolbar from '../components/toolbar';
import SceneDisplay from '../components/sceneDisplay';
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import TitleDisplay from "../components/title"
import jwtDecode from 'jwt-decode';
import {HoopSpinner} from "react-spinners-kit"
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    // bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundColor: '#141313'
  };

let language = [{ language: "English (United States)", code: "en-US" }, { language: "English (United Kingdom)", code: "en-GB" }, { language: "English (Australia)", code: "en-AU" }]
let voice = [
   { 
    gender:"Female",
    voice: "Aria", 
    code: "en-US-AriaNeural", 
    language: 0 },
   { 
    gender:"Female",
    voice: "Jenny", 
    code: "en-US-JennyNeural", 
    language: 0 },
   { 
    gender:"Male",
    voice: "Guy", 
    code: "en-US-GuyNeural", 
    language: 0 },
   { 
    gender:"Female",
    voice: "Sara", 
    code: "en-US-SaraNeural", 
    language: 0 },
   { 
    gender:"Male",
    voice: "Brandon", 
    code: "en-US-BrandonNeural", 
    language: 0 },
    { 
      gender:"Female",
      voice: "Libby", 
      code: "en-GB-LibbyNeural", 
      language: 1 },
   
    { 
      gender:"Female",
      voice: "Sonia", 
      code: "en-GB-SoniaNeural", 
      language: 1 },
    { 
      gender:"Male",
      voice: "Ryan", 
      code: "en-GB-RyanNeural", 
      language: 1 },
    { 
      gender:"Female",
      voice: "Mia", 
      code: "en-GB-MiaNeural", 
      language:1 },
    { 
      gender:"Female",
      voice: "Natasha", 
      code: "en-AU-NatashaNeural", 
      language: 2 },
    { 
      gender:"Male",
      voice: "William", 
      code: "en-AU-WilliamNeural", 
      language: 2 },
    { 
      gender:"Female",
      voice: "Catherine", 
      code: "en-AU-Catherine", 
      language: 2 },
    { 
      gender:"Female",
      voice: "Hayley", 
      code: "en-AU-HayleyRUS", 
      language: 2 },
 
  ]
const API_BASE = "https://demobe.inofii.com/copilot/";
// const API_BASE = "http://localhost:8000/copilot/";
const IstoryBoard = () => {
  const navigate = useNavigate();
  const [storyIntent, setStoryIntent] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [currentScene, setCurrentScene] = useState(0);
  const [isInputFocused, setInputFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [previewiStoryBtn, setPreviewiStoryBtn] = useState(false);
  const [istoryId, setIstoryId] = useState(null);
  const [dialogLoader, setDialogLoader] = useState(false)
  const [dialogs, setDialog] = useState()
  const [title, setTitle] = useState([])
  const [selecetdVoice,setSelectedVoice]=useState()
  const [selecetdLanguage,setSelectedLanguage]=useState("en-US")
  const [lisVoice,setListVoice]=useState([])
  const [listLanguage,setListLanguage]=useState([])
  const [storyCretionError,setStoryCreationError]=useState()
  const [counter,setCounter]=useState()
  const [assets,setAsset]=useState([])
  const [lastPanel,setLastPanel]=useState(false);
  const [api_usage,setApi_usage]=useState(0)
  const [restrictionOpen,setRestrictionOpen] = useState(false)

    useEffect(() => {
        
        const storedToken = localStorage.getItem('token');
        // console.log("coming",jwtDecode(storedToken).user_api_usage.istory)
        if (storedToken) {
          setToken(storedToken);
          // setApi_usage(jwtDecode(storedToken).user_api_usage.istory)
        } else {
            
            // Redirect to login page
            navigate("/login");
        }
      }, []);

    const handleOpen = () => {
      if(api_usage >3){
        setRestrictionOpen(true)
      }
      else{

        setOpen(true)
      }
    };

    const handleClose = () => setOpen(false);

    const RestrictionhandleClose = () => setRestrictionOpen(false);

    const handleInputFocus = () => setInputFocused(true);
    
    const handleInputBlur = () => setInputFocused(false);

  const handleSceneChange = (sceneNumber) => {
    setCurrentScene(sceneNumber);
    setSelectedLanguage(responseData[sceneNumber].description[0].language)

  };

    const handleSaveScene = async (e) => {

        e.preventDefault(); // 👈️ prevent page refresh
        setLoading(true);
        let story_id;
        let asset=[];

        //create istory

        const storyPayload = {
            name: "iStoryAssitiveCampaignDemo",
            status: "PUBLISHED",
            active: false
        };

        /////////////////asset creation////////////////

        const token=window.localStorage.getItem("token")
        const user=decodeToken(token).user_id
        // console.log(user)
        const assetPayload = {
          asset:responseData,
          user:user
        }
        const assetResponse = await axios.post(API_BASE + 'assetcreation/', assetPayload, {
            headers: {
              'Content-Type': 'application/json',
            },
        });

        if (assetResponse.status === 201) {
          try {
            setErrorMessage("")
            console.log("printing before asset variale")
            asset =  JSON.parse(assetResponse.data);
            setAsset(asset)
            console.log("assest data api", asset);

          } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);
          setErrorMessage("Please try again.");
        }

        //////////////////////////////////////////////

        try {
            const storyResponse = await axios.post('https://uat.istorydesigncenter.com/istory/list/', storyPayload, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
          
            if (storyResponse.status === 201) {
              try {
                setErrorMessage("")
                const responseData = storyResponse.data;
                setIstoryId(responseData.id)
                setCounter(0)
                console.log("story id created")
                story_id = responseData.id
              } catch (error) {
                setLoading(false);
              }
            } else {
              setLoading(false);
              setErrorMessage("Please try again.");
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage("An error occurred. Please try again later.");
        }

        //create istory scenes
        ///////////////////////////////////////////////////////
        // responseData.forEach(async (data, index) => {
        //   console.log("index: ", index)
        //   console.log("assets: ", asset)
        //   console.log("asset scene dialog: ", asset[index].dialogs)
        //     const scenePayload = {
        //       scene_name: `scene ${index + 1}`,
        //       scenetype: "NONINTERACTION",
        //       scene_dialogue: asset[index].dialogs.map((desc,i) => ({
        //         story_voice: "en-US-CoraNeural",
        //         dialog_text: desc,
        //         caption: asset[index].caption[i],
        //         dialog_pause_period: null
        //       })),
        //       interaction_from_scene: [],
        //       scene_play: "IMAGE",
        //       scene_img: asset[index].image,
        //     //   scene_img: data.image_caption,
        //       scene_video: null,
        //       navigation: null,
        //       scene_order: index + 1,
        //       story: story_id
        //     };
          
        //     try {
        //       const sceneResponse = await axios.post(`https://uat.istorydesigncenter.com/istory/${story_id}/scenes/`, scenePayload, {
        //         headers: {
        //           'Content-Type': 'application/json',
        //         },
        //       });
          
        //       if (sceneResponse.status === 201) {
        //         try {
        //           setErrorMessage("");
        //           const responseData = sceneResponse.data;console.log("parsed data from scene creating api", responseData);
        //         } catch (error) {console.error('Error parsing response data:', error);
        //           setLoading(false);
        //         }
        //       } else {
        //         setLoading(false);
        //         setErrorMessage("Please try again.");
        //       }
              
        //     } catch (error) {
        //       setLoading(false);
        //       setErrorMessage("An error occurred. Please try again later.");
        //     }
        //   });
        //////////////////////////////////////////////////////
        // setLoading(false);
        // setPreviewiStoryBtn(true);
    };

    const scene_saving= async ()=>{
      
     if (counter<responseData.length) {
      
      const scenePayload = {
        scene_name: `scene ${counter + 1}`,
        scenetype: "NONINTERACTION",
        scene_dialogue: assets[counter].dialogs.map((desc,i) => ({
          story_voice: "en-US-CoraNeural",
          dialog_text: desc,
          caption: assets[counter].caption[i],
          dialog_pause_period: null
        })),
        interaction_from_scene: [],
        scene_play: "IMAGE",
        scene_img: assets[counter].image,
      //   scene_img: data.image_caption,
        scene_video: null,
        navigation: null,
        scene_order: counter + 1,
        story: istoryId
      };
    
      try {
        const sceneResponse = await axios.post(`https://uat.istorydesigncenter.com/istory/${istoryId}/scenes/`, scenePayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (sceneResponse.status === 201) {
          try {
            setErrorMessage("");
            const responseData = sceneResponse.data;console.log("parsed data from scene creating api", responseData);
            setCounter(counter+1)
          } catch (error) {console.error('Error parsing response data:', error);
            setLoading(false);
          }
        } else {
          setLoading(false);
          setErrorMessage("Please try again.");
        }
        
      } catch (error) {
        setLoading(false);
        setErrorMessage("An error occurred. Please try again later.");
      }

      }
      
    
  //////////////////////////////////////////////////////
  else{
    setLoading(false);
  setPreviewiStoryBtn(true);

  }
  

    }
    useEffect(()=>{
      console.log("scene saving issue is created")
      scene_saving()

    },[counter,istoryId,assets])

    const handlePreviewiStory = (event) => {
      window.open(`https://uat.istorylive.com/?iStory_id=${istoryId}`, "_blank");
    };
    // // useEffect(()=>{
    // //     console.log("inside useeffect",istoryId)
    // //     window.open(`https://uat.istorylive.com/?iStory_id=${istoryId}`, "_blank");
    // // },[istoryId])
    // async function handleSubmit (e) {
    //     e.preventDefault(); // 👈️ prevent page refresh
    //     handleClose();
    //     setPreviewiStoryBtn(false)
    //     setLoading(true);
    //     const payload = {
    //         messages: [
    //           { role: 'user', content: storyIntent }
    //         ]
    //       };
    //     console.log("Submit");
    //     // https://demobe.inofii.com/copilot/version1/
    //     // https://demobe.inofii.com/copilot/version1/
    //     try {
    //         const response = await axios.post('https://demobe.inofii.com/copilot/version1/', payload, {
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //         });
          
    //         if (response.status === 201) {
    //           try {
    //             const parsedData = JSON.parse(response.data);
    //             console.log("parsed data", parsedData);
    //             setResponseData(parsedData);
    //             setLoading(false);
    //             setErrorMessage("")
    //           } catch (error) {
    //             console.error('Error parsing response data:', error);
    //             setLoading(false);
    //           }
    //         } else {
    //           setLoading(false);
    //           setErrorMessage("Please try again.");
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         setErrorMessage("An error occurred. Please try again later.");
    //     }
    // }
    const titleGeneration=async(e)=>{
      e.preventDefault(); // 👈️ prevent page refresh
      handleClose()
      setLoading(true);
      const api_usage_response = await axios.post(API_BASE + 'api_usage/',{"user_id":jwtDecode(token).user_id,"type":"istory"})
      if((api_usage_response.status === 200 && api_usage_response.data.count >= 0 )|| api_usage_response.data.user==7){

        // console.log(storyIntent)
        const payload= {messages:[
            {
              "role": "user",
              "content": storyIntent
            }
          ]}
          try {
                    const response = await axios.post(API_BASE + 'topic_generation/', payload, {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    });
                  
                    if (response.status === 201) {
                      try {
                        const parsedData = JSON.parse(response.data);
                        console.log("parsed data", parsedData);
                        // console.log(parsedData)
                        setResponseData([]);
                        setTitle(parsedData)
                        
                        setLoading(false);
                        setErrorMessage("")
                      } catch (error) {
                        console.error('Error parsing response data:', error);
                        setLoading(false);
                      }
                    } else {
                      setLoading(false);
                      setErrorMessage("Please try again.");
                    }
            } catch (error) {
                setLoading(false);
                setErrorMessage("An error occurred. Please try again later.");
            }
      }
      else if (api_usage_response.status === 200 && api_usage_response.data.count<0){
        setRestrictionOpen(true)
        setLoading(false);
      }
      else{
        setLoading(false);
        setErrorMessage("Please try again.");
      }
    }
    const modifyDialog= async(dialog,index)=>
    {
        setPreviewiStoryBtn(false)
        let payload
        if(dialog){
           payload={
            messages: dialog
        }
        
        }
        else{
           payload={
            messages: dialogs
        }
        }
        
        // console.log(payload)
        try {
            setDialogLoader(true)
        const response=await axios.post(API_BASE + "modifyDialogue/",payload,{headers: {
            'Content-Type': 'application/json',
          }})
          if (response.status === 201) {
            try {
                setResponseData((previous_value)=>{
                    let updateValues=[...previous_value]
                    const str = response.data.replace(/"/g,'');
                    let replaceSelected = updateValues[currentScene].description[index].text.replace(payload.messages, str);
                    updateValues[currentScene].description[index].text = replaceSelected
                    // updateValues[currentScene].description[index].text = response.data
                    // console.log(updateValues)
                    setDialogLoader(false)
                    return updateValues
        
                })
              
              setErrorMessage("")
            } catch (error) {
              console.error('Error parsing response data:', error);
              setLoading(false);
            }
          } else {
            setResponseData((previous_value)=>{
              let updateValues=[...previous_value]
              
              updateValues[currentScene].description[index].text="Please try again."
              setDialogLoader(false)
              return updateValues
  
          })
            setLoading(false);
            setErrorMessage("Please try again.");
          }
        }
        catch(error) {
            setLoading(false);
            setResponseData((previous_value)=>{
              let updateValues=[...previous_value]
              
              updateValues[currentScene].description[index].text="An error occurred. Please try again later."
              setDialogLoader(false)
              return updateValues
  
          })
            setErrorMessage("An error occurred. Please try again later.");
        }

    }
    const dialogChange=(value,index)=>{
        
        setDialog(value)
        setPreviewiStoryBtn(false)
        setResponseData((previous_value)=>{
            let updateValues=[...previous_value]
            
            updateValues[currentScene].description[index].text=value
            // setDialogLoader(false)
            return updateValues

        })
       
    }
    const deleteTitle=(index)=>{
      setTitle((prevalues)=>{
        let updateValues=[...prevalues]
        updateValues.splice(index,1)
        console.log((updateValues))
        return updateValues
      })

    }
    const addTitle=(index)=>{
      let create={title:"New Title"}
      setTitle((prevalues)=>{
        let updateValues=[...prevalues]
        updateValues.splice(index+1,0,create)
        console.log((updateValues))
        return updateValues
      })
      
    }
    const titleChange=(value,index)=>{
      setTitle((previous)=>{
        let updateValues=[...previous]
        updateValues[index].title=value
        return updateValues

    })
  }
  const createStory = async () => {
    setLastPanel(false);
    setCurrentScene(0);
    const topics = title.map(item => `'${item.title}'`)

    const promptText = storyIntent + ". " + "Titles are" + " " + `[${topics}]`
    setLoading("images");
    const payload = { "messages": [{ "role": "user", "content": promptText }] }
    try {
      // It's unclear if Axios supports streaming POST requests, so I use `fetch`.
      const response = await fetch(API_BASE + "version1-stream/", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        // responseType: "stream",
      });
      let panels = [];
      // console.log(response)

      if (response.status === 201) {
        try {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let streaming = "";

          setListLanguage(language);
          setListVoice(voice);

          function readChunk() {
            reader.read().then(({ value, done }) => {
              if (done) {
                if (streaming !== "") newPanel()
                return endLoad();
              }
              const chunk = decoder.decode(value);
              // console.log("Read chunk", chunk)
              // Weird behaviour where nginx doesn't process stream
              // chunks in the way that django sends them out ?????
              if (chunk.includes("}{")) {
                let [endOfChunk, startOfChunk] = chunk.split("}{")
                streaming += endOfChunk + "}";
                newPanel();
                streaming = "{" + startOfChunk;
              } else {
                streaming += chunk;
              }
              if (chunk.endsWith("}")) newPanel();
              readChunk();
            })
          }
          function newPanel() {
            try {
              let newPanel = JSON.parse(streaming);
              newPanel.description = newPanel.description.map(text => ({
                language: "en-US",
                voice: "en-US-AriaNeural",
                text,
              }));
              panels.push(newPanel);
              streaming = "";
              // All of the "last panel" stuff can be removed
              // I just haven't gotten around to doing it :(
              setLastPanel(panels.at(-1));
              setLoading(false);
              setResponseData(panels);
              setStoryCreationError("");
              setPreviewiStoryBtn(false);
            } catch (e) {
              console.log(e, streaming);
              setLoading(false);
              setStoryCreationError("Please try again.");
            }
          }
          function endLoad() {
            // console.log("parsed data", language_infused);
            // setResponseData(language_infused);
            // setStoryCreationError("")
            // setPreviewiStoryBtn(false)
          }
          readChunk();
          // stream.on("data", data => {
          //   let tmp = responseData;
          //   tmp.push(JSON.parse(data));
          //   console.log(data);
          //   setResponseData(() => tmp);
          // })
          // stream.on("end", () => {
          //   let language_infused=parsedData.map(item=>{
          //     let infused=item.description.map(text=>{
          //       let array={language:"en-US",voice:"en-US-AriaNeural",text:text}
          //       return array
          //     })
          //     setListLanguage(language)
          //     setListVoice(voice)
          //     item.description=infused
          //     return item
          //   })
          //   console.log("parsed data", language_infused);
          //   setResponseData(parsedData);
          //   setLoading(false);
          //   setStoryCreationError("")
          //   setPreviewiStoryBtn(false)
          // })
        } catch (error) {
          console.error('Error parsing response data:', error);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setStoryCreationError("Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.log("error occured", error)
      setStoryCreationError("An error occurred. Please try again later.");
    }
  }
  const selectedVoiceChange=(value,index)=>{
    setPreviewiStoryBtn(false)
    setResponseData((previous_value) => {
      let updateValues = [...previous_value]

      updateValues[currentScene].description[index].voice = value
      // setDialogLoader(false)
      console.log(updateValues)
      return updateValues

    })
  }
  const selecetdLanguageChange=(value,index)=>{
    setPreviewiStoryBtn(false)
    // let langFilter=language.filter((item,index)=>item.code===value)
    
    // let index_number=language.indexOf(langFilter[0])
    // let voices=voice.filter(item=>item.language==index_number)
   
    // setListVoice(voices)
    setResponseData((previous_value) => {
      let updateValues = [...previous_value]

      updateValues[currentScene].description.map(element => {
        element.language = value
        // element.voice = voices[0].code
      });
      // setDialogLoader(false)
      console.log(updateValues)
      return updateValues

    })
  }
  useEffect(()=>{
    if (responseData.length>0)
    {selecetdLanguageChange(selecetdLanguage)
    }
    
},[selecetdLanguage])
const deleteDialog=(index)=>{
  setPreviewiStoryBtn(false)
  setResponseData((prevalues)=>{
    let updateValues=[...prevalues]
    updateValues[currentScene].description.splice(index,1)
    // console.log((updateValues))
    return updateValues
  })

}
  return (
    <Grid container className='istoryContainer' >
      <Header></Header>
      {isLoading &&
        (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              backgroundColor: '#060606',
            }}
          >
            <div className="loading-container">
            {isLoading === "images" ?
              (
                lastPanel !== false ?
                <img style={{ width: 500 }} src={`data:image/png;base64,${lastPanel.cyoa_image_base64}`} />
                : <><HoopSpinner size={30} color="#fdb813" />
                <p className="loading-text">
              As our AI weaves your story, take a coffee break - this is just a demo, after all!.
                <br />
                Our top-tier product leaves this in the dust. Stick around, it's worth it 😉
              </p></>
              )
              : <><HoopSpinner size={30} color="#fdb813"  /><p className="loading-text">
              Embarking on an AI-Assisted Story Journey...
              <br />
              Please wait. It could take some time.
            </p></>
              }
              
            </div>
          </div>
        )
      }
      {title.length === 0 && !isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: "calc(100vh)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            {error && (
              <p style={{ color: "red" }}>{error}</p>
            )}
            <h1>Unleash the Power of AI-Assisted Storytelling</h1>
            <h2>Create Your First Copilot-Enhanced Story and Unleash Your Imagination</h2>
            <Box sx={{ textAlign: "center" }}>
              <Button 
              sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }} 
              variant="contained" onClick={handleOpen}>Generate Story</Button>
            </Box>
          </Box>
        </Box>

      )}

      {responseData.length > 0 && title.length > 0 && !isLoading && !error && (
        <Box >
          <CustomToolbar
            title={responseData[currentScene].title}
            handleOpen={handleOpen}
            handleSaveScene={handleSaveScene}
            previewiStoryBtn={previewiStoryBtn}
            handlePreviewiStory={handlePreviewiStory}
          />

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <SceneDisplay
              scene={{
                currentScene: currentScene,
                totalScenes: responseData.length,
                onSceneChange: handleSceneChange,
                scenesData: responseData,
                setScenesData: setResponseData,
                title: responseData[currentScene].title,
                description: responseData[currentScene].description,
                image_caption: responseData[currentScene].image_caption,
                cyoa_image_base64: responseData[currentScene].cyoa_image_base64,
                modifyDialog,
                loader: dialogLoader,
                setDialog,
                dialogChange,
                dialogs,
                voice:lisVoice,
                language:listLanguage,
                selecetdVoice:selecetdVoice,
                setSelectedVoice:setSelectedVoice,
                selectedVoiceChange:selectedVoiceChange,
                selecetdLanguage:selecetdLanguage,
                setSelectedLanguage:setSelectedLanguage,
                selecetdLanguageChange:selecetdLanguageChange,
                
                deleteDialog:deleteDialog
              }}
            />
          </motion.div>
          {/* <Button onClick={save_iStory}>save iStory</Button> */}

        </Box>)}
      {title.length > 0 && responseData.length == 0 && !isLoading && !error && (storyCretionError||!storyCretionError)&&(
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}

        >
          <TitleDisplay
            heading={{
              title: title,
              setTitle:setTitle,
              deleteTitle: deleteTitle,
              addTitle: addTitle,
              titleChange: titleChange,
              titleRegeneration: titleGeneration,
              handleOpen: handleOpen,
              createStory: createStory,
              error:storyCretionError

            }}
          />

        </motion.div>

      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: { backdropFilter: 'blur(8px)' }
          },
        }}
      >
        <Fade in={open} >
          <Box sx={style}>

            <Typography sx={{ color: "white" }} variant="h6" component="h2">
              Unveiling Infinite Creativity: Empower Your Story with our Copilot
            </Typography>

            <form onSubmit={titleGeneration}>
              <Box sx={{ mt: 2 }} >
                <label htmlFor="story-intention"
                  style={{
                    color: '#fdb813',
                    transition: 'color 0.3s',
                  }}>
                  Story Intention:
                </label>
                <input
                  type="text"
                  id="story-intention"
                  placeholder="Enter your intention"
                  value={storyIntent}
                  onChange={(e) => setStoryIntent(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  style={{
                    color: '#FFFFFF',
                    backgroundColor: '#2d2d2d',
                    border: `2px solid ${isInputFocused ? '#1565c0' : '#7e7f81'}`,
                    borderRadius: '4px',
                    padding: '16px',
                    width: '100%',
                    boxSizing: 'border-box',
                    transition: 'border-color 0.3s',
                    outline: 'none'
                  }}
                />
                {/* <TextField
                            label="Story Intention"
                            placeholder="Enter your intention"
                            variant="outlined"
                            value={storyIntent}
                            onChange={(e) => setStoryIntent(e.target.value)}
                            fullWidth
                            InputLabelProps={{ style: { color: '#7e7f81' } }}
                            InputProps={{
                            style: { color: '#FFFFFF' },
                            classes: {
                                root: { '& .MuiOutlinedInput-notchedOutline': { borderColor: '#FFFFFF' } },
                                notchedOutline: { borderColor: '#FFFFFF !important' },
                            },
                            }}
                        /> */}
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }} variant="contained" type="submit">Create</Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={restrictionOpen}
        onClose={RestrictionhandleClose}
        closeAfterTransition
      >
          <Box sx={{...style,textAlign:"center"}}>
            <Typography sx={{ color: "white", mb:2 }} variant='h5'>
            Out of demo credits? Contact us for more.
            </Typography>
            <Button variant='contained' sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }}>

              <Box component="a" href="https://arivu-iq.com/#ourContact" target="_blank" sx={{textDecoration:"none"}} >Contact US</Box>
            </Button>
          </Box>
      </Modal>
    </Grid>
  )
}
export default IstoryBoard;